import { UPDATE_IS_EVENT_CREATING, UPDATE_EVENTS_LOADING, UPDATE_EVENTS_SERVER } from "../actions/eventsActions";
import { StoreType } from "../types/store.types";

export interface ApiTrigger extends Trigger {
  id: string;
}

export interface Trigger {
  event_manager_id: string;
  trigger_type: string;
  tracked_id: string;
}

export interface EventManagerProps {
  chain: string;
  name: string;
  network: string;
  webhook_url: string;
  triggers: Trigger[];
}

export interface EventManagerItem {
  chain: string;
  id: string;
  name: string;
  network: string;
  status: string;
  webhook_url: string;
  triggers: ApiTrigger[];
}

export interface eventsStateType {
  eventsServer: EventManagerItem[];
  isEventCreating: boolean;
  isEventsLoading: boolean;
}

export const eventsInitialState: eventsStateType = {
  eventsServer: [] as EventManagerItem[],
  isEventCreating: false,
  isEventsLoading: false,
};

const eventsReducer = (state = eventsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_IS_EVENT_CREATING:
      return {
        ...state,
        isEventCreating: action.payload.status,
      };

    case UPDATE_EVENTS_LOADING:
      return {
        ...state,
        isEventsLoading: action.payload.status,
      };

    case UPDATE_EVENTS_SERVER:
      return {
        ...state,
        eventsServer: action.payload.eventsServer,
      };

    default:
      return state;
  }
};

export const getEvents = (state: StoreType) => state.events.eventsServer || [];
export const getIsEventCreating = (state: StoreType) => state.events.isEventCreating;
export const getIsEventsLoading = (state: StoreType) => state.events.isEventsLoading;

export default eventsReducer;
