import { useState, FC } from "react";
import { styled, Menu } from "@mui/material";

import { useDispatch } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import IconButton from "../../../components/Button/IconButton";

import { ThreeDotsIcon } from "../../../components/Icons/ThreeDotsIcon";
import { PenIcon } from "../../../components/Icons/PenIcon";

import { EventManagerItem } from "../../../redux/reducers/eventsReducer";

import { updatePopup } from "../../../redux/actions/popupsActions";
import { Popups } from "../../../types/popups";
import { copyToClipboard } from "../../../lib/copyToClipboard";
import { toast } from "react-toastify";

interface Props {
  event: EventManagerItem;
}

const EventOptions: FC<Props> = ({ event }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(updatePopup({ popup: Popups.deleteEvent, status: true, prefilled: { eventId: event.id } }));
    handleClose();
  };

  const handleEdit = () => {
    dispatch(
      updatePopup({
        popup: Popups.eventCreateWebhook,
        status: true,
        prefilled: event,
      }),
    );
    handleClose();
  };

  const handleCopy = () => {
    copyToClipboard(event.id);
    toast.success("Copied to clipboard");
    handleClose();
  };

  return (
    <Wrapper>
      <IconWrapper className="delete_project" onClick={handleEdit}>
        <PenIcon />
      </IconWrapper>
      <StyledIconButton onClick={handleClick}>
        <ThreeDotsIcon />
      </StyledIconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem onClick={handleCopy}>Copy webhook ID</StyledMenuItem>
        <StyledMenuItem onClick={handleDelete}>Delete</StyledMenuItem>
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "24.5px",
  justifyContent: "flex-end",
});

const StyledIconButton = styled(IconButton)({
  padding: "10px 18px",
});

const IconWrapper = styled(IconButton)(({ theme }) => ({
  padding: "11px",
  "& svg": {
    fill: "transparent",
  },
  "& svg path": {
    transition: "stroke 0.2s ease",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    "& svg path": {
      stroke: theme.palette.contrastText.main,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#333333",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export default EventOptions;
