//prettier-ignore
export const AddressMinusIcon = () => (
    <svg width="21" height="21" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.375 8.41602C0.375 3.9287 4.01269 0.291016 8.5 0.291016C12.9873 0.291016 16.625 3.9287 16.625 8.41602C16.625 12.9033 12.9873 16.541 8.5 16.541C4.01269 16.541 0.375 12.9033 0.375 8.41602Z"
            fill="#F5F5F5"
        />
        <path
            d="M4.35547 8.41615C4.35547 8.12537 4.59119 7.88965 4.88197 7.88965H12.253C12.5438 7.88965 12.7795 8.12537 12.7795 8.41615C12.7795 8.70693 12.5438 8.94266 12.253 8.94266H4.88197C4.59119 8.94266 4.35547 8.70693 4.35547 8.41615Z"
            fill="black"
        />
    </svg>
);
