import { CreateWebhookFields } from "../forms/createWebhook/types";
import { Blockchain, Network } from "../types/project";
import { getBlockchainLabel, getNetworkLabel } from "./createProject.utils";

export const getFieldRenderValue = (field: CreateWebhookFields, value: string, blockchain: Blockchain) => {
  if (field === CreateWebhookFields.blockchain) {
    return <span>{getBlockchainLabel(value as Blockchain)}</span>;
  }
  if (field === CreateWebhookFields.network) {
    return getNetworkLabel(blockchain, value as Network);
  }
  return value;
};
