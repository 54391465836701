import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getDeleteEventDialog, getPrefilled } from "../../redux/reducers/popupsReducer";

import Dialog from "./Dialog";
import { toast } from "react-toastify";

import { Popups } from "../../types/popups";
import { deleteEventServer } from "../../redux/actions/eventsActions";

const DeleteEventDialog = () => {
  const isOpen = useSelector(getDeleteEventDialog);
  const { eventId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteEvent, status: false }));
  };

  const handleDelete = () => {
    if (!eventId) {
      toast.error("Error occurred while deleting event");
      return;
    }
    dispatch(deleteEventServer(eventId));
    handleClose();
  };

  return (
    <Dialog open={isOpen}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Delete Event Manager
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Are you sure you want to delete this event manager? The action cannot be undone.
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleDelete}>Delete</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default DeleteEventDialog;
