import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { CreateWebhookFields } from "./types";
import { Option } from "../../types/newEntry";

interface GetCreateWebhookFieldsProps {
  blockchainOptions?: Option[];
  networkOptions?: Option[];
}

export const initialValuesCreateWebhook = {
  [CreateWebhookFields.network]: [],
  [CreateWebhookFields.blockchain]: [],
  [CreateWebhookFields.trigger]: [],
  [CreateWebhookFields.url]: "",
  [CreateWebhookFields.field]: [],
  [CreateWebhookFields.operator]: [],
  [CreateWebhookFields.value]: "",
};

export const getCreateWebhookFields = ({
  blockchainOptions,
  networkOptions,
}: GetCreateWebhookFieldsProps): NewEntryField[] => [
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.blockchain,
    placeholder: "Blockchain",
    options: [{ value: "bitcoin", label: "Bitcoin" }],
    fieldLabel: "Blockchain",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.network,
    placeholder: "Network",
    options: [
      { value: "mainnet", label: "Mainnet" },
      { value: "testnet", label: "Testnet4" },
    ],
    fieldLabel: "Network",
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateWebhookFields.url,
    placeholder: "Webhook URL",
    fieldLabel: "Webhook URL",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.trigger,
    placeholder: "Trigger",
    options: [{ value: "Transaction", label: "Transaction" }],
    fieldLabel: "Trigger",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.field,
    placeholder: "Field",
    options: [
      { value: "tx_hash", label: "tx_hash" },
      { value: "inputs_or_outputs", label: "inputs_or_outputs" },
    ],
    fieldLabel: "Field",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.operator,
    placeholder: "Operator",
    options: [
      { value: "equal to", label: "equal to" },
      { value: "contains", label: "contains" },
    ],
    fieldLabel: "Operator",
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateWebhookFields.value,
    placeholder: "Value",
    fieldLabel: "Value",
  },
];
