import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUser } from "../../redux/reducers/usersReducer";
import { getBillingInvoicesServer } from "../../redux/actions/userActions";
import { getIsAuthorized } from "../../redux/reducers/authReducer";

import SubscriptionPlansLayout from "../../layouts/SubscriptionPlansLayout";

import APIPackages from "./components/APIPackages";

import { SettingsPages } from "../../types/settings";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import { Package } from "../../redux/reducers/stripeReducer";
import {
  getSubscriptionPlans,
  getSubscriptionFeatures,
  getSubscriptionPlansLoading,
  getSubscriptionTiers,
} from "../../redux/reducers/stripeReducer";
import {
  getOldSubscriptionFeaturesServer,
  getSubscriptionFeaturesServer,
  getSubscriptionTiersServer,
  getOldSubscriptionTiersServer,
} from "../../redux/actions/stripeActions";
import Addons from "./components/Addons";
import { getIsVirtuosoAnnual, getSelectedTier } from "../../lib/subscriptionPlanUtils";
import { useCurrentSubscription } from "../../hooks/useCurrentSubscription";
import { SubscriptionPlan } from "../../types/subscription";

const path = [
  { title: "Settings", href: "/" },
  { title: "Subscription", href: "/" },
  { title: "Plans", href: "/" },
];

const Plans = () => {
  const dispatch = useDispatch();

  const [selectedPlan, setSelectedPlan] = useState<Package>();
  const [isVirtuosoAnnual, setIsVirtuosoAnnual] = useState(true);

  const user = useSelector(getUser);
  const isAuthorized = useSelector(getIsAuthorized);

  const plans = useSelector(getSubscriptionPlans);
  const features = useSelector(getSubscriptionFeatures);
  const tiers = useSelector(getSubscriptionTiers);
  const isSubscriptionsLoading = useSelector(getSubscriptionPlansLoading);

  const currentSubscription = useCurrentSubscription();

  const activeSubscriptionId = user.package_id;

  const selectedTier = getSelectedTier(tiers, isVirtuosoAnnual, selectedPlan);

  const isUserEnterprise = currentSubscription.name === SubscriptionPlan.enterprise;

  const handlePlanSelection = (selectedPlanId: string) => {
    const targetPlan = plans.find(({ id }) => selectedPlanId === id);
    if (!targetPlan || isUserEnterprise) return;

    setSelectedPlan(targetPlan);
  };

  const handleToogleSwitch = () => {
    if (isUserEnterprise) return;
    setIsVirtuosoAnnual(!isVirtuosoAnnual);
  };

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getBillingInvoicesServer());

    dispatch(getSubscriptionTiersServer());
    dispatch(getSubscriptionFeaturesServer());

    dispatch(getOldSubscriptionTiersServer());
    dispatch(getOldSubscriptionFeaturesServer());
  }, [isAuthorized]);

  useEffect(() => {
    if (
      plans.some(({ id }) => id === currentSubscription.id) ||
      currentSubscription.name === SubscriptionPlan.enterprise
    ) {
      setSelectedPlan(currentSubscription);
      setIsVirtuosoAnnual(getIsVirtuosoAnnual(tiers, currentSubscription, user));
    }
  }, [currentSubscription]);

  return (
    <SubscriptionPlansLayout
      path={path}
      active={SettingsPages.plans}
      selectedPlan={selectedPlan}
      selectedTier={selectedTier}
    >
      <Wrapper>
        <APIPackages
          isUserEnterprise={isUserEnterprise}
          selectedPlan={selectedPlan}
          subscriptionPlans={plans}
          isLoading={isSubscriptionsLoading}
          isVirtuosoAnnual={isVirtuosoAnnual}
          activeSubscriptionId={activeSubscriptionId}
          activeSubscriptionName={currentSubscription.name}
          subscriptionTiers={tiers}
          handleToogleSwitch={handleToogleSwitch}
          handlePlanSelection={handlePlanSelection}
        />
        <Addons activePlan={selectedPlan} services={features} />
      </Wrapper>
    </SubscriptionPlansLayout>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "64px",
  paddingBottom: "108px",
});

export default withPrivateRoute(Plans);
