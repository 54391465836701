import { Blockchain, Network } from "../../types/project";

export interface TriggerCondition {
  id: string;
  field: string[];
  operator: string[];
  value: string;
}

export interface CreateWebhookFormValues {
  network: Network[];
  blockchain: Blockchain[];
  trigger: string[];
  url: string;
}

export enum CreateWebhookFields {
  network = "network",
  blockchain = "blockchain",
  trigger = "trigger",
  field = "field",
  operator = "operator",
  value = "value",
  url = "url",
}
