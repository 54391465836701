export const CREATE_EVENT = "CREATE_EVENT_MANAGER";
export const UPDATE_IS_EVENT_CREATING = "UPDATE_IS_EVENT_MANAGER_CREATING";
export const GET_EVENTS_SERVER = "GET_EVENT_SERVER";
export const UPDATE_EVENTS_SERVER = "UPDATE_EVENT_SERVER";
export const UPDATE_EVENTS_LOADING = "UPDATE_EVENTS_LOADING";
export const DELETE_EVENT_SERVER = "DELETE_EVENT_SERVER";
export const UPDATE_EVENT_SERVER = "UPDATE_EVENT_SERVER";

import { EventManagerItem, EventManagerProps } from "../reducers/eventsReducer";

export const createEvent = (eventManagerData: EventManagerProps) => ({
  type: CREATE_EVENT,
  payload: {
    request: {
      method: "POST",
      url: "/eventmanager/managers",
      data: eventManagerData,
    },
  },
});

export const updateIsEventCreating = ({ status }: { status: boolean }) => ({
  type: UPDATE_IS_EVENT_CREATING,
  payload: { status },
});

export const getEventsServer = () => ({
  type: GET_EVENTS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/eventmanager/managers",
    },
  },
});

export const updateEventsServer = (eventsServer: EventManagerItem[]) => ({
  type: UPDATE_EVENTS_SERVER,
  payload: { eventsServer },
});

export const updateEventsLoading = ({ status }: { status: boolean }) => ({
  type: UPDATE_EVENTS_LOADING,
  payload: { status },
});

export const deleteEventServer = (id: string) => ({
  type: DELETE_EVENT_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: `/eventmanager/managers/${id}`,
    },
  },
});

export const updateEventServer = (eventData: EventManagerItem) => ({
  type: UPDATE_EVENT_SERVER,
  payload: {
    request: {
      method: "PUT",
      url: `/eventmanager/managers/${eventData.id}`,
      data: eventData,
    },
  },
});
