import { Blockchain, Network } from "./project";

export interface Event {
  id: string;
  chain: Blockchain;
  network: Network;
  url: string;
  trigger: EventTrigger;
  // events: number;
  status: EventStatus;
}

export enum EventTrigger {
  block = "Block",
  transaction = "Transaction",
}

export enum EventStatus {
  active = "active",
  paused = "paused",
}
