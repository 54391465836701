import { Package, PackagePriceData } from "../redux/reducers/stripeReducer";

export enum SubscriptionPlan {
  artist = "Artist",
  conductor = "Conductor",
  virtuoso = "Virtuoso",
  enterprise = "Enterprise",
}

export interface FeaturesProps {
  chains: string[];
  details: string[];
  name: string;
  packages: string[];
}

export interface PackagePriceProps extends Package, PackagePriceData {}
