import * as Yup from "yup";

import { CreateWebhookFields } from "./types";

export const createEventSchema = Yup.object().shape({
  [CreateWebhookFields.blockchain]: Yup.array().min(1, "Blockchain is required"),
  [CreateWebhookFields.network]: Yup.array().min(1, "Network is required"),
  [CreateWebhookFields.trigger]: Yup.array().min(1, "Trigger is required"),
  [CreateWebhookFields.url]: Yup.string()
    .matches(
      /\b((?:https?:\/\/|ftp:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i,
      "Invalid webhook URL",
    )
    .max(100, "URL must be 100 characters or less")
    .required("URL is required"),
});
