import React, { useEffect, useState } from "react";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Blockchain, Network } from "../../../types/project";
import { EntryType } from "../../../types/table";

import { updatePopup } from "../../../redux/actions/popupsActions";

import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TablePagination from "../../../components/Table/TablePagination";
import { FilledArrowDown, FilledArrowUp, SortTableIcon } from "../../../components/Icons";

import { compare } from "../../../lib/sort";
import { Popups } from "../../../types/popups";

import { getBlockchainIcon, getBlockchainLabel, getNetworkLabel } from "../../../lib/createProject.utils";

import { Event, EventStatus } from "../../../types/event";

import EventStatusChip from "./EventStatusChip";
import EventOptions from "./EventOptions";

import { EventManagerItem, getEvents, getIsEventsLoading } from "../../../redux/reducers/eventsReducer";
import { getEventsServer } from "../../../redux/actions/eventsActions";
import { useCurrentSubscription } from "../../../hooks/useCurrentSubscription";

import { checkLimit, getLimitTooltip } from "../../../lib/eventManagerUtils";
import Tooltip from "../../../components/Tooltip/Tooltip";

enum SortState {
  increasing = "increasing",
  descending = "descending",
}

interface SortedBy {
  columnName: string;
  state: SortState;
}

const EventsTable = () => {
  const events = useSelector(getEvents);
  const isLoading = useSelector(getIsEventsLoading);

  const currentSubscription = useCurrentSubscription();

  const { isLimitReached } = checkLimit({
    currentCount: events.length,
    subscriptionPlan: currentSubscription.name,
    limitType: "eventManagers",
  });

  const [sortedBy, setSortedBy] = useState<SortedBy | null>(null);
  const [isButtonTriggered, setIsButtonTriggered] = useState(false);

  const dispatch = useDispatch();

  const getHeadingSortIcon = (key: string) =>
    sortedBy?.columnName !== key || !sortedBy ? (
      <SortTableIcon />
    ) : sortedBy.state === SortState.increasing ? (
      <FilledArrowUp />
    ) : (
      <FilledArrowDown />
    );

  const heading: EntryType = {
    ID: "ID",
    blockchain: {
      title: "Blockchain",
      adornemnt: getHeadingSortIcon("blockchain"),
      adornmentPosition: "end",
    },
    network: {
      title: "Network",
      adornemnt: getHeadingSortIcon("network"),
      adornmentPosition: "end",
    },
    url: "URL",
    trigger: {
      title: "Trigger",
      adornemnt: getHeadingSortIcon("trigger"),
      adornmentPosition: "end",
    },
    status: {
      title: "Status",
      adornemnt: getHeadingSortIcon("status"),
      adornmentPosition: "end",
    },
    options: "",
  };

  const createRow = (event: EventManagerItem): EntryType => {
    return {
      ID: (
        <StyledTooltip
          title={
            <Typography variant="paragraphSmall" color="grey.A200">
              {event.id}
            </Typography>
          }
          followCursor
        >
          <WebhookUrl>{event.id}</WebhookUrl>
        </StyledTooltip>
      ),
      blockchain: {
        title: getBlockchainLabel(event.chain as Blockchain),
        adornemnt: getBlockchainIcon(event.chain as Blockchain),
        adornmentPosition: "start",
      },
      network: getNetworkLabel(event.chain as Blockchain, event.network as Network),
      url: (
        <StyledTooltip
          title={
            <Typography variant="paragraphSmall" color="grey.A200">
              {event.webhook_url}
            </Typography>
          }
          followCursor
        >
          <WebhookUrl>{event.webhook_url}</WebhookUrl>
        </StyledTooltip>
      ),
      trigger: event.name,
      status: <EventStatusChip status={event.status as EventStatus} />,
      options: <EventOptions event={event} />,
    };
  };

  const [projects, setProjects] = useState<EntryType[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<EntryType[]>([]);
  const [page, setPage] = React.useState(0);

  const handleNextPage = () => setPage(page + 1);

  const handlePrevPage = () => setPage(page - 1);

  const handlePageChenge = (newPage: number) => setPage(newPage);

  const sortByKey = (key: string) => {
    if (!sortedBy || sortedBy.columnName !== key) {
      setSortedBy({ columnName: key, state: SortState.increasing });
      setFilteredProjects((projects) =>
        projects.sort((project1: any, project2: any) =>
          compare(project1[key]?.title || project1[key], project2[key]?.title || project2[key]),
        ),
      );
      return;
    }
    if (sortedBy.state === SortState.increasing && sortedBy.columnName === key) {
      setSortedBy({ columnName: key, state: SortState.descending });
      setFilteredProjects((projects) =>
        projects
          .sort((project1: any, project2: any) =>
            compare(project1[key]?.title || project1[key], project2[key]?.title || project2[key]),
          )
          .reverse(),
      );
      return;
    }
    if (sortedBy.state === SortState.descending) {
      setSortedBy(null);
      return;
    }
  };

  const sortTable = [
    { columnName: "blockchain", handleClick: () => sortByKey("blockchain") },
    { columnName: "network", handleClick: () => sortByKey("network") },
    { columnName: "trigger", handleClick: () => sortByKey("trigger") },
    { columnName: "events", handleClick: () => sortByKey("events") },
    { columnName: "status", handleClick: () => sortByKey("status") },
  ];

  const handleClick = () => {
    setIsButtonTriggered(true);
    dispatch(
      updatePopup({
        popup: Popups.eventCreateWebhook,
        status: true,
      }),
    );
    setIsButtonTriggered(false);
  };

  const rowsPerPage = 5;

  useEffect(() => {
    dispatch(getEventsServer());
  }, []);

  useEffect(() => {
    if (!events) return;

    const mappedProjects = events.map((event) => ({
      ...event,
      chain: event.chain || Blockchain.Bitcoin,
    }));

    setProjects(mappedProjects.map(createRow));
    setFilteredProjects(mappedProjects.map(createRow));
  }, [events]);

  return (
    <OuterWrapper empty={!projects.length}>
      <Container>
        <InnerWrapper>
          <TableHeader
            triggerValue="Create Webhook"
            title="Events Manager"
            subtitle="Get notifications about on-chain events"
            newEntry={{
              handleClick,
              isLoading: false,
              disable: [
                {
                  condition: isLimitReached,
                  message: getLimitTooltip(currentSubscription.name, "eventManagers"),
                },
              ],
            }}
          />
          <Table
            data={filteredProjects}
            heading={heading}
            page={page}
            isLoading={isLoading}
            rowsPerPage={rowsPerPage}
            rowSx={getRowStyle()}
            empyStateMessage="No results. Start by adding your first event"
            sortTable={sortTable}
          />
          <TablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            entries={projects}
            allItemsLength={filteredProjects.length || 0}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handlePageChange={handlePageChenge}
          />
        </InnerWrapper>
      </Container>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")<{ empty: boolean }>(({ empty, theme }) => ({
  padding: `48px 0 ${empty ? "48px" : "64px"}`,
  marginBottom: "auto",

  [theme.breakpoints.down("lg")]: {
    padding: `0 0 ${empty ? "48px" : "56px"}`,
  },

  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const InnerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",

  [theme.breakpoints.down(709)]: {
    "& .MuiTable-root": {
      minWidth: "637px",
    },

    "& .MuiPaper-root": {
      overflow: "auto hidden !important",
    },

    "& .first-header-cell, & .first-body-cell": {
      position: "sticky",
      left: 0,
      background: theme.palette.common.white,
      boxShadow: "0px 0px 24px -6px rgba(0, 0, 0, 0.16)",
      marginRight: "38px",
    },
  },
}));

const getRowStyle = () => ({
  "& .delete_project": {
    visibility: "hidden",
    opacity: "0",
    transition: "0.3s",
    cursor: "pointer",
  },
  "&:hover": {
    "& .delete_project": {
      visibility: "visible",
      opacity: "1",
    },
  },
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "500px",
    width: "auto",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

const WebhookUrl = styled("div")({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "226px",
});

export default EventsTable;
