import { FC } from "react";

import { styled, Typography } from "@mui/material";

import { ProjectsIcon, WebhooksIcon, RequestsIcon } from "../../../components/Icons";

import { getWordPlural } from "../../../lib/subscriptionPlanUtils";
import Switch from "../../../components/Switch/Switch";

interface Props {
  isDisabled: boolean;
  throughputLimit: string;
  projectLimit: number;
  webhookLimit: number;
  isMobile?: boolean;
  isVirtuoso?: boolean;
  isVirtuosoAnnual: boolean;
  handleToogleSwitch: () => void;
}

const PlanPackageData: FC<Props> = ({
  isDisabled,
  throughputLimit,
  webhookLimit,
  projectLimit,
  isMobile,
  isVirtuoso,
  isVirtuosoAnnual,
  handleToogleSwitch,
}) => {
  return (
    <Wrapper>
      <Row disabled={isDisabled}>
        <RequestsIcon />
        <DataText color={isDisabled ? "grey.300" : "textColor.dark"}>{throughputLimit} requests/sec</DataText>
      </Row>
      <Row disabled={isDisabled}>
        <ProjectsIcon />
        <DataText color={isDisabled ? "grey.300" : "textColor.dark"}>
          {projectLimit} {getWordPlural(projectLimit, "project")}
        </DataText>
      </Row>
      <Row disabled={isDisabled}>
        <WebhooksIcon />
        <DataText color={isDisabled ? "grey.300" : "textColor.dark"}>
          {webhookLimit} {getWordPlural(webhookLimit, "webhook")}
        </DataText>
        {isVirtuoso && isMobile && (
          <ToogleWrapper isChecked={isVirtuosoAnnual}>
            <ToogleLeft>
              <Switch isDisabled={isDisabled} checked={isVirtuosoAnnual} handleChange={handleToogleSwitch} />
              <SwitchText isChecked={isVirtuosoAnnual}>{isVirtuosoAnnual ? "Yearly" : "Monthly"}</SwitchText>
            </ToogleLeft>
          </ToogleWrapper>
        )}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

  [theme.breakpoints.down(1512)]: {
    flexDirection: "column",
    gap: "12px",
  },
}));

const ToogleWrapper = styled("div")<{ isChecked: boolean }>(({ isChecked, theme }) => ({
  display: "flex",
  marginLeft: "auto",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "12px 12px 0px 0px",
  transition: "background-color 0.3s ease-in-out",

  [theme.breakpoints.down(1920)]: {
    justifyContent: "end",
    alignItems: "end",
    marginLeft: "auto",
  },
}));

const ToogleLeft = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "space-between",
});

const SwitchText = styled(Typography)<{ isChecked: boolean }>(({ theme, isChecked }) => ({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: isChecked ? theme.palette.primary.main : theme.palette.social.dark,
  transition: "color 0.3s ease-in-out",
}));

const Row = styled("div")<{ disabled: boolean }>(({ disabled, theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",

  "& svg": {
    flexShrink: "0",

    "& path": {
      stroke: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
    },
  },
}));

const DataText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",
});

export default PlanPackageData;
