import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled } from "@mui/material";
import { Button } from "@maestro-org/ui-kit";

import { getNewCreateWebhookDialog, getPrefilled } from "../../../redux/reducers/popupsReducer";
import { updatePopup } from "../../../redux/actions/popupsActions";
import { PlusIcon } from "../../Icons";

import Dialog from "../Dialog";
import Tooltip from "../../Tooltip/Tooltip";

import { Popups } from "../../../types/popups";

import { NewEntryField } from "../../../types/newEntry";

import { CreateWebhookFormValues } from "../../../forms/createWebhook/types";
import useFields from "../../../forms/createWebhook/useField";
import { getCreateWebhookFields } from "../../../forms/createWebhook/form";

import { TriggerCondition } from "../../../forms/createWebhook/types";
import DynamicFieldsGroup from "./components/DynamicFieldGroup";

import { AddressMinusIcon } from "../../Icons/AddressMinusIcon";

import { createEvent, updateEventServer } from "../../../redux/actions/eventsActions";
import { ApiTrigger, getEvents } from "../../../redux/reducers/eventsReducer";

import { useCurrentSubscription } from "../../../hooks/useCurrentSubscription";

import { checkLimit, getLimitTooltip } from "../../../lib/eventManagerUtils";

const EventCreateWebhookDialog = () => {
  const dispatch = useDispatch();

  const events = useSelector(getEvents);
  const currentSubscription = useCurrentSubscription();
  const prefillData = useSelector(getPrefilled) || {};
  const isEditMode = Boolean(prefillData?.id);
  const isOpen = useSelector(getNewCreateWebhookDialog);

  const useTriggersCount = (excludeEventId?: string) => {
    return events.reduce((total, event) => {
      if (event.id === excludeEventId) return total;
      return total + (event.triggers?.length || 0);
    }, 0);
  };

  const canAddTrigger = () => {
    const { isLimitReached } = checkLimit({
      currentCount: totalTriggersCount + triggerConditions.length,
      subscriptionPlan: currentSubscription.name,
      limitType: "triggers",
    });

    return !isLimitReached;
  };

  const totalTriggersCount = useTriggersCount(isEditMode && prefillData.id);

  const getAddTriggerTooltip = () => {
    if (!canAddTrigger()) {
      return getLimitTooltip(currentSubscription.name, "triggers");
    }
    return "";
  };

  const handleClose = () => {
    resetForm({
      values: {
        blockchain: [],
        network: [],
        trigger: [],
        url: "",
        field: [],
        operator: [],
        value: "",
      },
    });
    setTriggerConditions([{ id: "0", field: [], operator: [], value: "" }]);
    dispatch(updatePopup({ popup: Popups.eventCreateWebhook, status: false }));
  };

  const onSubmit = (values: CreateWebhookFormValues) => {
    const formData = {
      chain: values.blockchain[0],
      network: values.network[0],
      name: values.trigger[0],
      webhook_url: values.url,
      triggers: triggerConditions.map((condition) => ({
        event_manager_id: "",
        trigger_type: "transaction",
        tracked_id: condition.value,
        ...(isEditMode && { id: condition.id }),
      })),
      ...(isEditMode && { id: prefillData.id, status: prefillData.status }),
    };

    dispatch(isEditMode ? updateEventServer(prefillData.id && prefillData.status && formData) : createEvent(formData));
    handleClose();
  };

  const { values, isValid, dirty, getField, handleSubmit, resetForm } = useFields({ onSubmit });

  const [triggerConditions, setTriggerConditions] = useState<TriggerCondition[]>([
    {
      id: "0",
      field: [],
      operator: [],
      value: "",
    },
  ]);

  const handleAddTrigger = () => {
    if (canAddTrigger()) {
      setTriggerConditions((prev) => [...prev, { id: String(prev.length), field: [], operator: [], value: "" }]);
    }
  };

  const areTriggerConditionsValid = (): boolean => {
    return triggerConditions.every(
      (condition) => condition.field.length > 0 && condition.operator.length > 0 && condition.value.trim() !== "",
    );
  };

  const isButtonDisabled = () => {
    if (isEditMode) {
      return !isValid || !areTriggerConditionsValid();
    }
    return !isValid || !dirty || !areTriggerConditionsValid();
  };

  useEffect(() => {
    if (isEditMode) {
      const updatedTriggerConditions = prefillData.triggers?.map((trigger: ApiTrigger) => ({
        id: trigger.id,
        field: ["tx_hash"] as never[],
        operator: ["equal to"] as never[],
        value: trigger.tracked_id,
      })) || [
        {
          id: "0",
          field: ["tx_hash"] as never[],
          operator: ["equal to"] as never[],
          value: "",
        },
      ];

      setTriggerConditions(updatedTriggerConditions);

      const prefilledData = {
        blockchain: [prefillData.chain] as never[],
        network: [prefillData.network] as never[],
        trigger: [prefillData.name] as never[],
        url: prefillData.webhook_url,
        field: ["tx_hash"] as never[],
        operator: ["equal to"] as never[],
        value: updatedTriggerConditions[0].value,
      };
      resetForm({ values: prefilledData });
    }
  }, [isEditMode]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Create Event Webhook
        </Typography>
        <FormFields>
          {getCreateWebhookFields({})
            .slice(0, 2)
            .map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>
        <FormFields>
          {getCreateWebhookFields({})
            .slice(2, 4)
            .map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>
        <ButtonWrapper>
          <Line />
          <StyledTooltip
            title={
              <Typography variant="paragraphSmall" color="grey.A200">
                {getAddTriggerTooltip()}
              </Typography>
            }
            followCursor
            disabled={canAddTrigger()}
          >
            <AddButton onClick={handleAddTrigger} disabled={!canAddTrigger()}>
              <Circle>
                <PlusIcon />
              </Circle>
              <ButtonText>Add Trigger conditions</ButtonText>
            </AddButton>
          </StyledTooltip>
          <Line />
        </ButtonWrapper>
        {triggerConditions.map((condition, index) => (
          <FormFields key={condition.id} equalWidth>
            <DynamicFieldsGroup
              condition={condition}
              onFieldChange={(fieldName: string, value: any) => {
                setTriggerConditions((prev) =>
                  prev.map((item) => (item.id === condition.id ? { ...item, [fieldName]: value } : item)),
                );
              }}
              fields={getCreateWebhookFields({}).slice(4, 7)}
            />
            {index > 0 && (
              <DeleteRow
                onClick={() => {
                  setTriggerConditions((prev) => prev.filter((item) => item.id !== condition.id));
                }}
              >
                <AddressMinusIcon />
              </DeleteRow>
            )}
          </FormFields>
        ))}
        <Actions>
          <ActionInnerWrapper>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={isButtonDisabled()}>
              {isEditMode ? "Edit" : "Create"}
            </Button>
          </ActionInnerWrapper>
        </Actions>
      </Form>
    </Dialog>
  );
};

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "& > *:first-child": {
    marginBottom: "12px",
  },
});

const FormFields = styled("div")<{ equalWidth?: boolean }>(({ equalWidth }) => ({
  display: "flex",
  gap: "20px",
  position: "relative",
  "& > div:first-of-type": {
    width: "100%",
  },
  ...(equalWidth && {
    "& > div": {
      width: "100%",
    },
  }),
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  columnGap: "12px",
  marginTop: "20px",
});

const ActionInnerWrapper = styled("div")({
  display: "flex",
  gap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const AddButton = styled("div")<{ disabled: boolean }>(({ disabled }) => ({
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "15px",
  cursor: "pointer",
  opacity: disabled ? 0.5 : 1,
}));

const ButtonText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#C53DD8",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "10px 0px",
});

const Line = styled("span")({
  display: "flex",
  height: "1px",
  background: "#D9D9D9",
  width: "25%",
});

const Circle = styled("div")({
  display: "flex",
  padding: "7px",
  borderRadius: "50%",
  background: "#C53DD8",
});

const DeleteRow = styled("div")({
  display: "flex",
  width: "unset !important",
  justifyContent: "center",
  alignItems: "center",
  padding: "3px",
  position: "absolute",
  right: "-33px",
  top: "20.5px",
  borderRadius: "50%",
  cursor: "pointer",
  transition: "all 0.3s",

  "&:hover": {
    "& path:first-of-type": {
      fill: "#C53DD8",
      transition: "all 0.3s",
    },
    "& path:last-of-type": {
      fill: "#FFF",
    },
  },
});

const StyledTooltip = styled(Tooltip)<{ disabled: boolean }>(({ theme, disabled }) => ({
  "& .MuiTooltip-tooltip": {
    ...(disabled && { display: "none" }),
    maxWidth: "236px",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

export default EventCreateWebhookDialog;
