import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { PathElement } from "../types/breadcrumpsPathTypes";
import { CheckoutSteps, SettingsPages } from "../types/settings";

import SettingsNavigation from "../components/Navigation/SettingsNavigation";
import SettingsSidebar from "../modules/Settings/components/SettingsSidebar";
import Breadcrumps from "../components/Breadcrumps/Breadcrumps";
import PlansRightSidebar from "../modules/Settings/components/PlansRightSidebar";
import PlansMobileCheckout from "../modules/Settings/components/PlansMobileCheckout";
import { DocumentationIcon } from "../components/Icons";

import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/reducers/usersReducer";
import { getActiveCheckoutStep, Package, PackagePriceData } from "../redux/reducers/stripeReducer";
import { sidebarItems } from "./SettingsLayout";

import PaymentSuccess from "../modules/Settings/components/PaymentSuccess";
import PaymentFail from "../modules/Settings/components/PaymentFail";
import PaymentInProcess from "../modules/Settings/components/PaymentInProcess";
import Checkout from "../modules/Settings/components/Checkout";
import OrderSummary from "../modules/Settings/components/OrderSummary";
import { getBillingDataServer, setActiveCheckoutStep } from "../redux/actions/stripeActions";
import { DesktopOnly } from "../lib/stylingUtils";
import { useCurrentSubscription } from "../hooks/useCurrentSubscription";
import useIsMobile from "../hooks/useIsMobile";
import { SubscriptionPlan } from "../types/subscription";

interface Props {
  selectedPlan?: Package;
  selectedTier?: PackagePriceData;
  children: React.ReactNode;
  active: SettingsPages;
  path: PathElement[];
}

const SubscriptionPlansLayout = ({ children, active, path, selectedPlan, selectedTier }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const checkoutStep = useSelector(getActiveCheckoutStep);
  const isMobile = useIsMobile();
  const currentPlan = useCurrentSubscription();

  const isCheckoutDisabled =
    !selectedPlan || currentPlan.id === selectedPlan.id || selectedPlan.name === SubscriptionPlan.enterprise;

  const handleChangeStep = (step?: CheckoutSteps) => dispatch(setActiveCheckoutStep({ step }));

  const handleCheckoutClick = () => handleChangeStep(CheckoutSteps.checkout);

  const handleBackClick = () => handleChangeStep(CheckoutSteps.orderSummary);

  const handlePayClick = () => handleChangeStep(CheckoutSteps.waitingForPayment);

  const getStep = {
    [CheckoutSteps.orderSummary]: (
      <OrderSummary
        selectedPlan={selectedPlan}
        selectedTier={selectedTier}
        handleCheckoutClick={handleCheckoutClick}
        handleBackClick={() => handleChangeStep()}
        isCheckoutDisabled={isCheckoutDisabled}
      />
    ),
    [CheckoutSteps.checkout]: (
      <Checkout handleBackClick={handleBackClick} handlePayClick={handlePayClick} selectedPlan={selectedPlan} />
    ),
    [CheckoutSteps.waitingForPayment]: <PaymentInProcess selectedPlan={selectedPlan} selectedTier={selectedTier} />,
    [CheckoutSteps.success]: <PaymentSuccess />,
    [CheckoutSteps.failure]: <PaymentFail handleBackClick={handleBackClick} selectedPlan={selectedPlan} />,
  };

  useEffect(() => {
    if (!user) return;
    dispatch(getBillingDataServer());
  }, [user]);

  useEffect(() => {
    handleChangeStep();
  }, [selectedPlan]);

  return (
    <OuterWrapper>
      <SettingsSidebar active={active} items={sidebarItems} />
      <Wrapper>
        <SettingsNavigation path={path} actions={<SettingsNavActions />} />
        <ContentWrapper>
          <Content isWhiteBackground={!!checkoutStep}>
            <BreadcrumpsWrapper>
              <Breadcrumps path={path} />
            </BreadcrumpsWrapper>
            <DesktopOnly>{children}</DesktopOnly>
            {isMobile && <>{checkoutStep ? getStep[checkoutStep] : children}</>}
          </Content>
          {!isMobile && <PlansRightSidebar>{getStep[checkoutStep || CheckoutSteps.orderSummary]}</PlansRightSidebar>}
          {!checkoutStep && (
            <PlansMobileCheckout
              isMobileCheckoutDisabled={isCheckoutDisabled}
              handleNextStep={() => handleChangeStep(CheckoutSteps.orderSummary)}
            />
          )}
        </ContentWrapper>
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")({
  display: "flex",
  height: "100vh",
  overflowY: "hidden",
});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflowY: "hidden",
  flexGrow: 1,
  minWidth: 0,
});

const ContentWrapper = styled("div")({
  display: "flex",
  height: "100%",
});

const Content = styled("div")<{ isWhiteBackground: boolean }>(({ theme, isWhiteBackground }) => ({
  background: theme.palette.grey[50],
  minWidth: 0,
  display: "flex",
  padding: "56px 160px 110px 100px",
  flexDirection: "column",
  rowGap: "56px",
  flexGrow: 1,
  overflow: "hidden auto",

  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "8px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  [theme.breakpoints.down(1850)]: {
    padding: "56px 50px 110px 50px",
  },

  [theme.breakpoints.down(1750)]: {
    padding: "56px 30px 110px 30px",
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "60px",
  },

  [theme.breakpoints.down(1101)]: {
    padding: "24px",
  },

  [theme.breakpoints.down("md")]: {
    paddingBottom: "140px",
    background: isWhiteBackground ? theme.palette.common.white : theme.palette.grey[50],
  },

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 140px",
    rowGap: "48px",
  },
}));

const BreadcrumpsWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1101)]: {
    display: "none",
  },
}));

const SettingsNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

export default SubscriptionPlansLayout;
