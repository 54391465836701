import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  CREATE_EVENT,
  updateIsEventCreating,
  GET_EVENTS_SERVER,
  updateEventsServer,
  updateEventsLoading,
  getEventsServer,
  DELETE_EVENT_SERVER,
  UPDATE_EVENT_SERVER,
} from "../actions/eventsActions";

const eventsSaga = [
  takeEvery(CREATE_EVENT, handleCreateEvent),
  takeEvery(`${CREATE_EVENT}_SUCCESS`, handleCreateEventSuccess),
  takeEvery(`${CREATE_EVENT}_FAIL`, handleCreateEventFail),

  takeEvery(GET_EVENTS_SERVER, handleGetEventsServer),
  takeEvery(`${GET_EVENTS_SERVER}_SUCCESS`, handleGetEventsServerSuccess),
  takeEvery(`${GET_EVENTS_SERVER}_FAIL`, handleGetEventsServerFail),

  takeEvery(DELETE_EVENT_SERVER, handleDeleteEventServer),
  takeEvery(`${DELETE_EVENT_SERVER}_SUCCESS`, handleDeleteEventServerSuccess),
  takeEvery(`${DELETE_EVENT_SERVER}_FAIL`, handleDeleteEventServerFail),

  takeEvery(UPDATE_EVENT_SERVER, handleUpdateEventServer),
  takeEvery(`${UPDATE_EVENT_SERVER}_SUCCESS`, handleUpdateEventServerSuccess),
  takeEvery(`${UPDATE_EVENT_SERVER}_FAIL`, handleUpdateEventServerFail),
];

function* handleCreateEvent() {
  yield put(updateIsEventCreating({ status: true }));
}

function* handleCreateEventFail(action: any) {
  yield put(updateIsEventCreating({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleCreateEventSuccess() {
  yield put(updateIsEventCreating({ status: false }));
  yield put(getEventsServer());
  toast.success("New event was successfully created");
}

function* handleGetEventsServer() {
  yield put(updateEventsLoading({ status: true }));
}

function* handleGetEventsServerSuccess(action: any) {
  yield put(updateEventsServer(action.payload.data.data));
  yield put(updateEventsLoading({ status: false }));
}

function* handleGetEventsServerFail(action: any) {
  yield put(updateEventsLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleDeleteEventServer() {
  yield put(updateEventsLoading({ status: true }));
}

function* handleDeleteEventServerSuccess() {
  yield put(getEventsServer());
  yield put(updateEventsLoading({ status: false }));
  toast.success("Event Manager successfully deleted");
}

function* handleDeleteEventServerFail(action: any) {
  yield put(updateEventsLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleUpdateEventServer() {
  yield put(updateEventsLoading({ status: true }));
}

function* handleUpdateEventServerSuccess() {
  yield put(getEventsServer());
  yield put(updateEventsLoading({ status: false }));
  toast.success("Event Manager successfully updated");
}

function* handleUpdateEventServerFail(action: any) {
  yield put(updateEventsLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

export default eventsSaga;
