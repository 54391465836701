import { Package } from "../redux/reducers/stripeReducer";
import { SubscriptionPlan } from "../types/subscription";

export const enterpriseMocks: Package = {
  id: "enterpise",
  name: SubscriptionPlan.enterprise,
  description: "enterpise",
  credits_per_day: 0,
  credits_per_month: 0,
  project_limit: 10,
  webhook_limit: 10,
  throughput_limit: "",
  features: [],
};
